import React from 'react';
import styled from 'styled-components';

import { Column, Grid } from 'src/components/molecules/Grid';
import { Button } from 'src/components/atoms/Button';
import { FluidImg } from 'src/embeds/image/image-dato';
import * as css from './drawer-case-study-teaser.css';
import * as sys from '../../system';

const ImageColumn = styled(Column)`
  ${({ verticalAlign }) => verticalAlign == 'end' && 'margin-top: -3rem'};
`;
const ContentColumn = styled(Column)`
  padding: 3rem 0;
`;
const ArticleTeaser = ({ image, children, verticalAlign = 'end' }) => (
  <Grid verticalAlign={verticalAlign} columns="1fr 2fr" spacious>
    <ImageColumn verticalAlign={verticalAlign}>{image}</ImageColumn>
    <ContentColumn>{children}</ContentColumn>
  </Grid>
);

export default function Drawer({ callouts, customData }) {
  callouts = callouts.filter((callout) => callout.__typename == 'DatoCmsCaseStudyBlock');
  customData = JSON.parse(customData);
  const { heading, content, contentNode, caseStudy, image } = callouts.find(
    (callout) => callout.__typename == 'DatoCmsCaseStudyBlock',
  );

  return (
    <section css={[css.section]}>
      <div css={[css.grid, sys.frame, css.wrap]}>
        <div css={[css.textCell]}>
          <p className="f-mono" css={[css.metaHeading, css.hilite]}>
            Customer Stories
          </p>
          <h3 css={[css.heading]}>{heading}</h3>
          {content.length ? (
            <div
              css={[css.content]}
              dangerouslySetInnerHTML={{ __html: contentNode.childMarkdownRemark.html }}
            />
          ) : null}
          <Button to={`/customers/${caseStudy.slug}`}>Read more</Button>
        </div>
        <div css={[css.imageCell]}>
          <FluidImg data={image} />
        </div>
      </div>
    </section>
  );
}
